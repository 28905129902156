
.topbar[data-v-0c727b52] {
  padding: 20px;
  background: #efefef;
}
.title[data-v-0c727b52] {
  font-size: 120%;
  font-weight: bold;
}
.options[data-v-0c727b52] {
  padding: 20px 30px;
}
.options ul[data-v-0c727b52] {
  line-height: 30px;
}
