
.content[data-v-a94cb022] {
  padding: 20px;
}
.content h1[data-v-a94cb022] {
  font-size: 100%;
  font-weight: normal;
  padding: 0;
  margin: 0;
}
.content p[data-v-a94cb022]:first-child {
  padding-top: 0;
  margin-top: 0;
}
.site-search input[type=text][data-v-a94cb022] {
  height: 2em;
}
.site-search select[data-v-a94cb022] {
  height: 2.4em;
}
.site-search input[type=submit][data-v-a94cb022] {
  height: 2.4em;
}
