
.summary[data-v-6592e483] {
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  margin-bottom: 1.5em;
  padding: 0;
  background-color: #fdfdfd;
  border: 1px solid #ccc;
  font-size: 13px;
}
.summary p[data-v-6592e483] {
  margin: 0 0 10px 0;
  padding: 0;
}
.summary h2[data-v-6592e483] {
  margin: 0;
  padding: 0;
  font-size: 1.4em;
  color: #000;
}
.summary h3[data-v-6592e483] {
  margin: 0 0 20px 0;
  padding: 0;
  color: #000;
}
.summary h4[data-v-6592e483] {
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 1em;
  font-weight: bold;
}
.summary .content[data-v-6592e483] {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
}
.summary .topbar[data-v-6592e483] {
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
}
.summary .topbar-quickview[data-v-6592e483] {
  flex: 1;
  font-weight: bold;
  font-size: 120%;
}
.summary .topbar-quickview-icon[data-v-6592e483] {
  align-self: center;
  padding-right: 10px;
}
.summary .topbar-quickview span.urgency[data-v-6592e483] {
  color: #00a3e3;
}
.summary .topbar-quickview span.Immediate[data-v-6592e483] {
  color: red !important;
}
.summary .topbar-quickview .topbar-quickview-value[data-v-6592e483] {
  display: block;
}
.summary .topbar-quickview-divider[data-v-6592e483] {
  display: none;
}
@media only screen and (min-width: 400px) {
.summary .topbar[data-v-6592e483] {
    margin-bottom: 4px;
}
.summary .topbar-quickview .topbar-quickview-value[data-v-6592e483] {
    display: initial;
}
.summary .topbar-quickview-divider[data-v-6592e483] {
    display: initial;
}
}
.summary .topbar-action[data-v-6592e483] {
  flex: 0 0;
}
.summary .activeDates[data-v-6592e483] {
  font-size: inherit;
}
.summary .expires[data-v-6592e483],
.summary .detailLink[data-v-6592e483] {
  margin: 1em 0;
}
.summary .detailLink a[data-v-6592e483]:link {
  text-decoration: underline;
  color: #135897;
}
.summary .detailLink a[data-v-6592e483]:hover {
  text-decoration: none;
}
.expand-xml[data-v-6592e483] {
  position: relative;
  left: -10px;
  width: calc(100% + 20px);
  padding: 20px;
  background: #efefef;
  margin: 10px 0;
  border-bottom: 1px solid #cdcdcd;
}
.expand-xml-uri[data-v-6592e483] {
  font-family: monospace;
  margin: 5px 0 10px;
  font-size: 95%;
}
.expand-xml-note[data-v-6592e483] {
  font-style: italic;
  font-size: 90%;
}
.expand-xml-type[data-v-6592e483] {
  font-family: monospace;
  font-size: 90%;
  font-weight: bold;
}
.expand-details[data-v-6592e483] {
  margin-bottom: 20px;
}
.expand-details-raw[data-v-6592e483] {
  left: -10px;
  position: relative;
  width: calc(100% + 20px);
  font-family: monospace;
  white-space: pre-line;
  background: #efefef;
  padding: 20px;
}
.expand-details-headline[data-v-6592e483] {
  margin-bottom: 10px;
}
.expand-details-description[data-v-6592e483] {
  margin-bottom: 10px;
}
.expand-details h3[data-v-6592e483] {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 110%;
}
.expand-details-references[data-v-6592e483] {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.expand-details-reference[data-v-6592e483] {
  margin-bottom: 4px;
}
.expand-details-info[data-v-6592e483] {
  display: table;
  margin-top: 10px;
}
.expand-details-info-set[data-v-6592e483] {
  display: table-row;
}
.expand-details-info-label[data-v-6592e483],
.expand-details-info-data[data-v-6592e483] {
  display: table-cell;
  padding: 4px 10px 4px 0;
}
.expand-details-info-label[data-v-6592e483] {
  font-weight: bold;
}
