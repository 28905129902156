
.content[data-v-7f7ef788] {
  padding: 20px 20px 20px 10px;
}
.content h1[data-v-7f7ef788] {
  font-size: 1.2em;
}
.content p[data-v-7f7ef788]:first-child {
  padding-top: 0;
  margin-top: 0;
}
.content .noaa-logo[data-v-7f7ef788] {
  width: 260px;
}
.banner-official[data-v-7f7ef788] {
  background: #f1f1f1;
  line-height: 2.2em;
  padding: 0 10px;
}
.banner-official img[data-v-7f7ef788] {
  box-sizing: content-box;
  background: #f1f1f1;
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

/* stylelint-disable-next-line no-descending-specificity */
.banner-official p[data-v-7f7ef788] {
  display: inline-block;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 12px;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
