
.input[data-v-a008ccd2] {
  background-color: #efefef;
  color: #333;
  height: 40px;
}
.input input[data-v-a008ccd2] {
  height: 100%;
  width: 100%;
  padding-left: 12px;
}
