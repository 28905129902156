
.option-control-select[data-v-4f8586d0] {
  background-color: #efefef;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 40px;
  width: 100%;
  border: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}
.option[data-v-4f8586d0] {
  background-color: #efefef;
  color: #333;
  padding: 8px 8px;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
.option[data-v-4f8586d0]:hover {
  background-color: #333;
  color: #fff;
}
