
#locationOptions[data-v-7e8b527c] {
  list-style-type: none;
  padding-left: 0;
}
#locationOptions li[data-v-7e8b527c] {
  margin-top: 20px;
}
.point[data-v-7e8b527c] {
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
  align-items: center;
  line-height: 38px;
}
.pointSegment[data-v-7e8b527c] {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
}
.pointSegmentLabel[data-v-7e8b527c] {
  width: 24px;
}
.point input[data-v-7e8b527c] {
  height: 30px;
  font-size: 102%;
  padding: 10px;
}
.pointMessage[data-v-7e8b527c] {
  font-style: italic;
}
.pointButton[data-v-7e8b527c] {
  width: 60px;
  height: 30px;
}
