
.panel[data-v-2b198006] {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  max-width: 500px;
  box-shadow: 6px 4px 12px 0 rgb(0, 0, 0, 0.2);
  z-index: 200000;
}
.content[data-v-2b198006] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 46px;
  overflow: auto;
}
.action[data-v-2b198006] {
  display: flex;
  flex: 1 auto;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}
.button[data-v-2b198006] {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #0099d8;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.action .button.close[data-v-2b198006] {
  width: 120px;
}
.action .button.explore[data-v-2b198006] {
  flex: 1;
}
.action .button.shell[data-v-2b198006] {
  flex: 1;
  cursor: default;
}
.button-icon[data-v-2b198006] {
  margin-right: 10px;
}
