
.navigation[data-v-19f3195b] {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}
.navigation > a[data-v-19f3195b] {
  display: block;
}
.search-results-section[data-v-19f3195b] {
  border-left: 5px solid #999;
  background: #efefef;
  margin-bottom: 10px;
}
.search-results-section-title[data-v-19f3195b] {
  padding: 10px 10px;
  font-size: 106%;
  font-weight: bold;
  cursor: pointer;
}
.search-results-section-content[data-v-19f3195b] {
  padding: 4px 10px 10px 20px;
}
.drawerIcon[data-v-19f3195b] {
  transition: 0.5s;
  font-size: 20px;
  vertical-align: text-bottom;
  margin-right: 3px;
}
.rotateIconDown[data-v-19f3195b] {
  transform: rotate(90deg);
}
.search-results-id-input[data-v-19f3195b] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}
