
.container[data-v-e82944ee] {
  line-height: 20px;
}
.url[data-v-e82944ee] {
  overflow-x: auto;
  background: rgb(0, 0, 0, 0.05);
  padding: 10px;
  font-size: 90%;
  margin: 10px 0 0;
}
