
.banner[data-v-4efd6a9e] {
  box-sizing: content-box;
  font-family: prox-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 46px;
  background: white;
  color: #333;
  line-height: 40px;
  padding: 0 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  z-index: 100000;
}
.logo[data-v-4efd6a9e] {
  display: inline-block;
  width: 63px;
  height: 30px;
}
.logo img[data-v-4efd6a9e] {
  width: 63px;
}
.title[data-v-4efd6a9e] {
  font-size: 18px;
  margin-left: 6px;
}
.title-product[data-v-4efd6a9e] {
  font-size: 80%;
  font-family: prox-regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.title-long[data-v-4efd6a9e] {
  display: none;
}
@media screen and (min-width: 530px) {
.title-short[data-v-4efd6a9e] {
    display: none;
}
.title-long[data-v-4efd6a9e] {
    display: initial;
}
}
@media screen and (min-width: 630px) {
.title[data-v-4efd6a9e] {
    font-size: 20px;
}
.title-long[data-v-4efd6a9e] {
    display: initial;
}
}
.menu[data-v-4efd6a9e] {
  margin-left: 20px;
  display: flex;
}
.button[data-v-4efd6a9e] {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #0099d8;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.menu .button[data-v-4efd6a9e] {
  width: 50px;
  height: 46px;
}
