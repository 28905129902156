* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

p:first-child {
  margin-top: 0;
}

@font-face {
  font-family: prox-light;
  src: url(/prox-light.71a32b3c.woff);
}

@font-face {
  font-family: prox-regular;
  src: url(/prox-regular.2d099f94.woff);
}

@font-face {
  font-family: prox-bold;
  src: url(/prox-bold.e54a6054.woff);
}

@font-face {
  font-family: prox-heavy;
  src: url(/prox-heavy.fbd5224f.woff);
}
