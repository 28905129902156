
.option[data-v-b96832b0] {
  padding: 0 6px 6px;
}
.optionActions[data-v-b96832b0] {
  display: flex;
  gap: 10px;
}
.option[data-v-b96832b0]:nth-child(even) {
  background-color: rgb(245, 252, 255);
  border-radius: 5px;
}
.optionAction[data-v-b96832b0] {
  white-space: nowrap;
  line-height: 15px;
  padding: 15px 0 4px;
}
.description[data-v-b96832b0] {
  margin-top: 4px;
  line-height: 22px;
}
.expand[data-v-b96832b0] {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}
.align-right[data-v-b96832b0] {
  text-align: right;
}
.drawer[data-v-b96832b0] {
  margin-top: 5px;
  padding: 10px 10px 10px;
}
