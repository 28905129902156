
li.treelist-item[data-v-1a17ccc0] {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
li.treelist-item a[data-v-1a17ccc0] {
  display: inline-block;
  margin: 1px 0;
  padding: 7px 40px 7px 10px;
  border-left: 10px solid #fff;
  text-decoration: none;
  color: #0a4595;
  cursor: pointer;
  vertical-align: middle;
}
li.treelist-item a.router-link-exact-active[data-v-1a17ccc0],
li.treelist-item a.router-link-active[data-v-1a17ccc0] {
  border-left: 10px solid #0099d8;
  font-weight: bold;
  color: black;
  cursor: default;
}
li.treelist-item a[data-v-1a17ccc0]:not(.router-link-exact-active):hover {
  border-left: 10px solid #efefef;
  background: #efefef;
}
li.treelist-item .treelist-item-icon[data-v-1a17ccc0] {
  margin-left: 5px;
  vertical-align: middle;
  transition: transform 500ms ease;
}
li.treelist-item .treelist-item-icon-open[data-v-1a17ccc0] {
  transform: rotate(180deg);
}
