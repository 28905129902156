
.optionsList[data-v-7db95c65] {
  margin-left: 0;
  list-style-type: none;
  padding-left: 20px;
}
.optionsListLabel[data-v-7db95c65] {
  font-size: 100%;
}
.optionsList .landZonesOptions[data-v-7db95c65] {
  display: flex;
  gap: 10px;
}
.optionsList .landZonesOption[data-v-7db95c65] {
  display: none;
}
@media (min-width: 520px) {
.optionsList .landZonesDefault[data-v-7db95c65] {
    display: none;
}
.optionsList .landZonesOption[data-v-7db95c65] {
    display: initial;
}
}
.optionsPrimaryList[data-v-7db95c65] {
  padding-left: 0;
}
.emptyset[data-v-7db95c65] {
  font-style: italic;
}
.filter[data-v-7db95c65] {
  max-width: 300%;
}
.filter input[data-v-7db95c65] {
  height: 40px;
  font-size: 102%;
  padding: 10px;
}
.type[data-v-7db95c65] {
  margin-top: 10px;
}
.order[data-v-7db95c65] {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.order > select[data-v-7db95c65] {
  display: block;
  font-size: 16px;
}
