
input[data-v-38d1b1ec] {
  height: 40px;
  background-color: #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: relative;
  width: 100%;
  padding-left: 12px;
  padding-right: 44px;
  font-weight: 400;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  z-index: 0;
}
input[data-v-38d1b1ec]::-webkit-outer-spin-button,
input[data-v-38d1b1ec]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number][data-v-38d1b1ec] {
  -moz-appearance: textfield;
}
