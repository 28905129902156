
.search-navigation[data-v-9bff2b20] {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}
.search-navigation > a[data-v-9bff2b20] {
  display: block;
}
.search-results[data-v-9bff2b20] {
  margin-top: 20px;
  margin-bottom: 50px;
}
.details-updated > span[data-v-9bff2b20] {
  white-space: nowrap;
}
.search-results-section[data-v-9bff2b20] {
  border-left: 5px solid #999;
  background: #efefef;
  margin-bottom: 10px;
}
.search-results-section-title[data-v-9bff2b20] {
  padding: 10px 10px;
  font-size: 106%;
  font-weight: bold;
  cursor: pointer;
}
.search-results-section-content[data-v-9bff2b20] {
  padding: 4px 10px 10px 20px;
}
.drawerIcon[data-v-9bff2b20] {
  transition: 0.5s;
  font-size: 20px;
  vertical-align: text-bottom;
  margin-right: 3px;
}
.rotateIconDown[data-v-9bff2b20] {
  transform: rotate(90deg);
}
.search-results-section-instructions[data-v-9bff2b20] {
  margin-bottom: 10px;
}
.search-results-refine-option[data-v-9bff2b20] {
  margin-bottom: 26px;
}
.search-results-refine-optionLabel[data-v-9bff2b20] {
  font-weight: bold;
  display: flex;
  gap: 10px;
  align-items: center;
}
.search-results-refine-options[data-v-9bff2b20] {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-top: 5px;
}
.search-results-options-history[data-v-9bff2b20] {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: center;
}
.search-results-options-history > div[data-v-9bff2b20]:first-child {
  width: 40px;
}
.search-results-refine-icon-history[data-v-9bff2b20] {
  font-size: 30px;
  cursor: pointer;
}
.search-results-refine-options > label[data-v-9bff2b20] {
  white-space: nowrap;
}
.search-results-refine-options > label input[data-v-9bff2b20] {
  font-size: 16px;
  padding: 5px;
}
.search-results-refine-options > label input[type=checkbox][data-v-9bff2b20] {
  transform: scale(1.5);
}
.search-results-refine-options > select[data-v-9bff2b20] {
  font-size: 14px;
  padding: 10px 2px;
}
.search-results-refine-button[data-v-9bff2b20] {
  margin-top: 20px;
}
