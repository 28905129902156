
.input[data-v-3930ead3] {
  background-color: #efefef;
  color: #333;
  height: 40px;
}
.edit-mode[data-v-3930ead3] {
  height: initial;
}
.input-display[data-v-3930ead3] {
  cursor: pointer;
}
.input input[data-v-3930ead3] {
  height: 40px;
  background-color: #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: relative;
  width: 100%;
  padding-left: 12px;
  padding-right: 44px;
  font-weight: 400;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  z-index: 0;
}
.editor[data-v-3930ead3] {
  background: #efefef;
}
.actionbar[data-v-3930ead3] {
  display: flex;
  background: #dfdfdf;
  height: 30px;
  align-items: center;
  border-top: 1px solid #aaa;
  margin-top: 10px;
}
.actionbar-action[data-v-3930ead3] {
  flex: 0 0;
  width: initial;
  padding: 0 18px;
  color: #333;
  border-right: 1px solid #aaa;
  align-self: stretch;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.actionbar-action-confirm[data-v-3930ead3] {
  background: #555;
  color: #fff;
  font-weight: bold;
  border-right: none;
}
.actionbar-action-confirm-disabled[data-v-3930ead3] {
  color: #777;
  cursor: default;
}
.actionbar-action-cancel[data-v-3930ead3] {
  color: #777;
}
.instructions[data-v-3930ead3] {
  padding: 4px;
  font-size: 85%;
}
