
.container[data-v-f8991eac] {
  padding: 10px 20px;
  font-size: 98%;
  border-bottom: 1px solid #efefef;
  display: flex;
}
.pathWizard[data-v-f8991eac] {
  display: flex;
}
.pathLink[data-v-f8991eac] {
  padding-left: 5px;
}
.pathWizardPrefix[data-v-f8991eac] {
  padding-left: 5px;
}
