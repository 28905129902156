
.select-more[data-v-2935f458] {
  color: #adadad;
  cursor: default;
}
.multiselect__tag-custom[data-v-2935f458] {
  cursor: pointer;
}
.multiselect__tag-custom[data-v-2935f458]:focus,
.multiselect__tag-custom[data-v-2935f458]:hover {
  background: #369a6e;
}
