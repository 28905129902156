
.status[data-v-4a8b228c] {
  margin-top: 10px;
  padding: 20px;
  font-style: italic;
  border: 1px dashed #cdcdcd;
  border-radius: 8px;
}
.status-icon[data-v-4a8b228c] {
  color: #444;
  margin-right: 7px;
}
.updated[data-v-4a8b228c] {
  margin-top: 10px;
  font-size: 90%;
}
.no-break[data-v-4a8b228c] {
  white-space: nowrap;
}
